@use '@angular/material' as mat;
@import './variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.all-component-typographies($psl-custom-typography);
@include mat.core();

// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($psl-app-theme);

.extended-color {
  $alternate-primary: mat.define-palette($psl-other-color, 50);
  $alternate-accent: mat.define-palette($psl-other-color, 100);
  $alternate-theme: mat.define-light-theme(
    (
      color: (
        primary: $alternate-primary,
        accent: $alternate-accent,
      ),
    )
  );
  @include mat.all-component-colors($alternate-theme);
}
