/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// @import "@angular/material/prebuilt-themes/pink-bluegrey.css";

@import 'scss/variables';
@import 'scss/fonts';
@import 'scss/theme';
@import 'scss/layout';
@import 'scss/forms';
@import 'scss/tabs';
@import 'scss/tables';
@import 'scss/charts';

@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';

a,
a:visited {
  color: mat.get-color-from-palette($psl-app-accent);
  text-decoration: none;
  fill: mat.get-color-from-palette($psl-app-accent);
}

a:hover,
a:visited:hover,
a:active,
a:focus {
  text-decoration: underline;
  color: darken(mat.get-color-from-palette($psl-app-accent), 10%);
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.no-wrap {
  white-space: nowrap;
}

.snackbar-no-padding {
  padding: 0px 0px 0px 0px !important;
}

.cdk-overlay-pane {
  .mat-snack-bar-container {
    max-width: 46vw;
  }
}

.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.mat-tooltip {
  font-size: 14px;
}
.dont-show-checkbox {
  label {
    color: mat.get-contrast-color-from-palette($psl-primary-color, 300);
  }
}

::ng-deep .tooltip-autoscale {
  max-width: unset !important;
  width: unset !important;
}

@media screen and (max-width: 991px) {
  .mat-mdc-paginator-container {
    justify-content: center !important;

    .mat-mdc-paginator-range-label {
      margin: 0 !important;
      white-space: nowrap;
    }
  }
}
