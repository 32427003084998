@use '@angular/material' as mat;
body {
  overflow: hidden;
  margin: 0;
  background: $body-bg;
  font-size: mat.font-size($psl-custom-typography, body-1);
  font-family: mat.font-family($psl-custom-typography);
  color: mat.get-color-from-palette($psl-primary-color, 500);
}

.mat-mdc-dialog-surface {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.boxed-in {
  background: #fff;
  padding: 25px;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-top: -5px;

  h1 {
    text-transform: uppercase;
    font-size: 1.25rem;
    margin: 0;
  }
}

.container {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $border-color;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .new-button {
      padding-left: 40px;
      padding-right: 40px;

      mat-icon {
        margin-left: 15px;
        height: 30px;
        width: 30px;
      }
    }
  }

  .table-container {
    width: 100%;
    table {
      width: 100%;
      border-collapse: true;
      border: 1px solid #cdcdcd;

      .mat-header-row {
        height: 30px;
        background-color: #f8f8f8;

        .mat-header-cell,
        ::ng-deep .mat-sort-header-button {
          text-transform: uppercase;
        }
      }

      #buttons-cell {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}

.reports {
  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;

    .current {
      padding: 0 30px;
      font-size: 1.25rem;
    }

    button {
      text-transform: uppercase;

      ::ng-deep .mat-button-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}

.notes_icon {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

.dashboard-img {
  height: 26px;
}

.colored-box {
  height: 30px;
  width: 38px;
  float: left;
  margin-top: 4px;
  margin-right: 10px;
}

.severity-table-col .colored-round {
  height: 30px;
  width: 30px;
  margin-top: 4px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 100%;
}

.severity-table-col .colored-rect {
  height: 20px;
  width: 40px;
  margin-top: 6px;
  margin-right: auto;
  margin-left: auto;
}

.loading-full-screen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  opacity: 0.5;
  z-index: 100;
}

.role {
  width: 100%;
  padding-bottom: 10px;

  mat-radio-group {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    mat-radio-button {
      width: 33%;
    }
  }
}

.alerts-notification {
  height: 28px;
  float: left;
  margin-right: 10px;
}

.colored-circle {
  border: 3px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: relative;
  top: -2px;
  z-index: 3;
  left: 16px;
}

.colored-circle-fleet {
  border: 3px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: relative;
  top: -6px;
  z-index: 3;
  left: 9px;
}

.premium-icon {
  height: 22px !important;
  width: 22px !important;
  color: #ffd700;
  margin-left: 3px;
}
